@use '@angular/material' as mat;
@import "@angular/material/theming";
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&display=swap");

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
@mixin title($fontSize, $color, $fontFamily, $align) {
  font-size: $fontSize;
  color: $color;
  font-family: $fontFamily;
  text-align: $align;
}
@font-face {
  font-family: almoni-medium;
  src: url("/assets/fonts/almoni-neue-tzar-aaa-medium.eot");
  src: url("/assets/fonts/almoni-neue-tzar-aaa-medium.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/almoni-neue-tzar-aaa-medium.woff") format("woff"),
    url("/assets/fonts/almoni-neue-tzar-aaa-medium.otf");
}

@font-face {
  font-family: almoni-thin;
  src: url("/assets/fonts/almoni-neue-tzar-aaa-thin.otf");
}

@font-face {
  font-family: almoni-light;
  src: url("/assets/fonts/almoni-neue-tzar-aaa-light.eot");
  src: url("/assets/fonts/almoni-neue-tzar-aaa-light.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/almoni-neue-tzar-aaa-light.woff") format("woff"),
    url("/assets/fonts/almoni-neue-tzar-aaa-light.otf");
}

@font-face {
  font-family: almoni-bold;
  src: url("/assets/fonts/almoni-neue-tzar-aaa-bold.eot");
  src: url("/assets/fonts/almoni-neue-tzar-aaa-bold.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/almoni-neue-tzar-aaa-bold.woff") format("woff"),
    url("/assets/fonts/almoni-neue-tzar-aaa-bold.otf");
}

@font-face {
  font-family: almoni-regular;
  src: url("/assets/fonts/almoni-neue-tzar-aaa-regular.eot");
  src: url("/assets/fonts/almoni-neue-tzar-aaa-regular.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/almoni-neue-tzar-aaa-regular.woff") format("woff"),
    url("/assets/fonts/almoni-neue-tzar-aaa-regular.otf");
}

@font-face {
  font-family: mikhmoret-regular;
  src: url("/assets/fonts/mikhmoret-regular-aaa.eot");
  src: url("/assets/fonts/mikhmoret-regular-aaa.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/mikhmoret-regular-aaa.woff") format("woff"),
    url("/assets/fonts/mikhmoret-regular-aaa.otf");
}

@font-face {
  font-family: mikhmoret-bold;
  src: url("/assets/fonts/mikhmoret-bold-aaa.eot");
  src: url("/assets/fonts/mikhmoret-bold-aaa.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/mikhmoret-bold-aaa.woff") format("woff"),
    url("/assets/fonts/mikhmoret-bold-aaa.otf");
}

@font-face {
  font-family: mikhmoret-light;
  src: url("/assets/fonts/mikhmoret-light-aaa.eot");
  src: url("/assets/fonts/mikhmoret-light-aaa.eot?#iefix") format("embedded-opentype"),
    url("/assets/fonts/mikhmoret-light-aaa.woff") format("woff"),
    url("/assets/fonts/mikhmoret-light-aaa.otf");
}

:root {
  --main_color: #303746;
  --white-color: #fff;
  --black-color: #303746;
  --danger-color: #d42b2b;
  --main_button_color: #3cb79c;
  --main_titles_color: #157454;
  --line-employee-card: #157454;
  --main_borders_color: #3ab79c;
  --main_borders_fill_color: #3ab79c;
  --label-color: #303746;
  --check_color: #ffffff;
  --header_background_color: #303746;
  --menu_background_color: #303746;
  --menu_links_color: #3ab79c;
  --footer_menu_links_color: #ffffff;
  --text-color: black;
  --arrow-color: #88b7a7;
  --select-border-color: #147354;
  --main-font-medium: almoni-medium;
  --main-font-light: almoni-light;
  --main-font-bold: almoni-bold;
  --main-font-regular: almoni-regular;
  --secondary-font-regular: mikhmoret-regular;
  --secondary-font-bold: mikhmoret-bold;
  --secondary-font-light: mikhmoret-light;
  --body-background-color: white;
  --line-color: #157454;
  --container-title-background: rgba(60, 183, 156, 0.28);
  --container-title-background-home: rgba(50, 128, 146, 0.1);
  --container-open-card: rgba(60, 183, 156, 0.1);
  --font-size-page-title-mobile: 30px;
  --font-size-title-mobile: 35px;
  --font-size-subtitle: 20px;
  --font-size-label-mobile: 20px;
  --font-size-button-mobile: 25px;
  --header-last-conected: 15px;
  --font-size-header-title-mobile: 30px;
  --font-size-card-title-mobile: 22px;
  --font-size-card-sub-title: 24px;
  --font-size-card-subtitle-mobile: 18px;
  --font-size-card-text-mobile: 16px;
  --height-card-home-page-mobile: 118px;
  --font-size-title-stage-mobile: 30px;
  --font-size-input-mobile: 26px;
  --font-size-error-message: 19px;
  --font-size-error-message-mobile: 16px;
  --font-size-recommend-to-user-desktop-mobile: 14px;
  --font-size-info-card-mobile: 19px;
  --font-size-card-title-home-mobile: 22px;
  --font-family-heebo: "Heebo", sans-serif;

  --font-size-label-desktop: 30px; //for buttons too
  --font-size-subtitle-desktop: 30px;
  --font-size-input-desktop: 30px;
  --font-size-error-message-desktop: 25px;
  --font-size-recommend-to-user-desktop: 21px;
  --font-size-phone-button-message-desktop: 28px;
  --font-header-title-desktop: 50px;
  --font-popup-header-title-desktop: 48px;
  --font-size-card-title-desktop: 35px;
  --font-size-card-subtitle-desktop: 28px;
  --font-size-label-input-desktop: 22px;
  --font-size-checkbox-desktop: 25px;
  --font-size-card-title-appointments-desktop: 40px;
  --font-size-card-subtitle-appointments-desktop: 25px;
  --font-size-card-text-right-desktop: 30px;
  --font-size-card-title-appointments-type-desktop: 35px;
  --font-size-card-text-left-desktop: 30px;
  --font-bread-crumbs-text: 25px;
  --font-size-tab-desktop: 40px;
  --font-size-select-desktop: 24px;
  --font-size-select-mobile: 22px;
  --font-size-select-option-desktop: 22px;
  --font-size-select-option-mobile: 20px;
  --font-size-select-label-desktop: 26px;
  --font-size-select-label-mobile: 24px;


  // toaster colors
  --toaster-success-color: #155724;
  --toaster-success-bg-color: #d4edda;
  --toaster-success-border-color: #c3e6cb;
  --toaster-error-color: #721c24;
  --toaster-error-bg-color: #f8d7da;
  --toaster-error-border-color: #f5c6cb;
  --toaster-warning-color: #856404;
  --toaster-warning-bg-color: #fff3cd;
  --toaster-warning-border-color: #ffeeba;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:hover {
  -webkit-font-style: almoni-regular;

  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px none inset;
  transition: background-color 5000s ease-in-out 0s;
}

//for accesability
h2,
h1,
h3,
h4 {
  font-weight: 400 !important;
}

// *:focus{
//   position: relative;
//   outline:0;
//   //outline: 1px solid  #157454;
//   border: 0.5px solid var(--main_titles_color);
//   border-radius: 5px;
//   //padding: 5px 5px 2px 5px;
//   outline-offset: 5px;
//   box-shadow: 0 0 0 3px  var(--main_titles_color);

// }
*:focus {
  outline: 0;
}

.ghost {
  background-color: #e3e3e3;
  height: 100%;
  color: #e3e3e3;

  border-radius: 5px;
  animation: fadein 2.5s;
  -moz-animation: fadein 2.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 2.5s;
  /* Internet Explorer */
  -o-animation: fadein 2.5s;
  /* Opera < 12.1 */
  -webkit-animation: fadein 2.5s;
  /* Safari, Chrome and Opera > 12.1 */
  animation-iteration-count: infinite;

  * {
    visibility: hidden;
  }
}

@keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
}

div:focus {
  box-shadow: none;
  border: none;
}

nav:focus {
  box-shadow: none;
  border: none;
}

.transparent {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

* {
  padding: 0px;
  margin: 0px;
  -webkit-overflow-scrolling: touch;
}

body {
  overflow: scroll;
}

.checkbox-text {
  @include title(20px, var(--main_color), var(--main-font-light), right);
}

.disabled {
  opacity: 0.5;
}

.canvasjs-chart-toolbar,
.canvasjs-chart-credit {
  display: none;
}

.mat-radio-group {
  display: flex;
  flex-flow: row-reverse nowrap;
  padding-top: 15px;
  //padding-right: 10px;
  //background:rgba(240, 240, 240, 0.28);;
}

.mat-radio-button {
  direction: rtl;
}

.mat-radio-button {
  // width: 100px;
  text-align: right;
  float: right;
  margin-right: 15px;
}

.mat-radio-button.mat-accent.mat-radi.mat-form-field-appearance-fill .mat-form-field-infix-checked .mat-radio-outer-circle {
  border-color: var(--main_borders_color);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: var(--main_borders_color);
  background-color: var(--main_borders_color);
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgb(255, 37, 37, 0.26);
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(1) !important;
}

.mobile {}

.line-img {
  margin-top: -34px;
}

.mat-option {
  &.mat-active::ng-deep {
    position: relative;
    outline: 0;
    border: 2px solid var(--main_titles_color);
    border-radius: 5px;
    outline-offset: 5px;
    box-shadow: 0 0 0 1px var(--main_titles_color);
  }
}

.noscroll {
  overflow: hidden;
}

.arrow_btn {
  width: 5px;
  height: 5px;
  border: 2.5px solid;
  border-color: var(--arrow-color) transparent transparent var(--arrow-color);
  transform: rotate(-135deg);
}

.arrow_left {
  transform: rotate(-45deg);
  border-color: #fff transparent transparent #fff;
}

@media screen and (max-width: 599px) {
  .fxHideXs {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .fxHideGtXs {
    display: none !important;
  }

  .fxShowGtXs {
    display: block !important;
  }
}

.tooltip-inner {
  max-width: 250px;
  padding: 15px;
  color: #fff;
  text-align: center;
  background-color: #303746;
  font-family: var(--secondary-font-light);
  line-height: 25px;
  font-size: 17px;
  direction: rtl;
  margin-left: 51px;
}

.tooltip {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.actions-btns {
  app-button {
    button {
      width: 100%;
    }
  }
}